/**
 * Default options
 */
 const defaultOptions = {
  offset: 120,
  delay: 0,
  easing: `ease`,
  duration: 400,
  disable: false,
  once: true,
  mirror: false,
  anchorPlacement: `top-bottom`,
  startEvent: `DOMContentLoaded`,
  animatedClassName: `aos-animate`,
  initClassName: `aos-init`,
  useClassNames: false,
  rootMargin: null,
};

class AOS {
  static init(options = {}) {
    return new AOS(options);
  }

  constructor(options = {}) {
    options = this.options = { ...defaultOptions, ...options };

    /**
     * Set global settings on body, based on options so CSS can use it
     */
    const body = document.querySelector(`body`);
    body.setAttribute(`data-aos-easing`, options.easing);
    body.setAttribute(`data-aos-duration`, options.duration);
    body.setAttribute(`data-aos-delay`, options.delay);

    let offset = options.offset;
    offset = isNaN(offset) ? offset : `${offset}px`;

    const observer = this.observer = new IntersectionObserver((entries, _observer) => {
      entries.forEach((entry) => {
        const { target, intersectionRatio } = entry;

        if (intersectionRatio > 0) {
          target.classList.add(options.animatedClassName);

          fireEvent(`aos:in`, entry);

          if (options.once) {
            observer.unobserve(target);

            // clear the aos after specified delay/duration so things like hover effects can still work.
            let delay = target.getAttribute("data-aos-delay") ? parseInt(target.getAttribute("data-aos-delay")) : options.delay;
            let duration = target.getAttribute("data-aos-duration") ? parseInt(target.getAttribute("data-aos-duration")) : options.duration;
            setTimeout(() => {
              target.removeAttribute("data-aos");
            }, (delay + duration));
          }
        } else {
          target.classList.remove(options.animatedClassName);
          fireEvent(`aos:out`, entry);
        }
      });
    }, {
      rootMargin: options.rootMargin || `0px 0px -${offset} 0px`
    });

    const elements = [].filter.call(
      document.querySelectorAll(`[data-aos]`),
      element => !element.classList.contains(this.options.animatedClassName)
    );

    // start observing your elements
    elements.forEach((element) => observer.observe(element));
  }
}

export default AOS;

const fireEvent = (eventName, data) => {
  const customEvent = new CustomEvent(eventName, {
    bubbles: true,
    detail: data
  });

  return data.target.dispatchEvent(customEvent);
};