import BlazeSlider from 'blaze-slider';
const body = document.querySelector("body");
const slider = document.querySelector(".blaze-slider");

if (slider) {
  const blaze = new BlazeSlider(slider, {
    all: {
      slidesToScroll: 1,
      slidesToShow: 3,
      loop: false,
    },
    "(max-width: 1024px)": {
      slidesToShow: 2
    },
    "(max-width: 600px)": {
      slidesToShow: 1,
      loop: true
    }
  });

  if (body.classList.contains("samadmin")) {
    blaze.track.onpointerdown = null;
  }
}