const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
const videoLinks = $$(".video-embed a");

function youtube_parser(url){
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : undefined;
}

function vimeo_parser(url){
  const match = /vimeo.*\/(\d+)/i.exec(url);
  if (match) {
    return match[1];
  } else {
    return undefined;
  }
}

videoLinks.forEach((link) => {
  const url = link.href;
  let formattedURL = "";

  if (url.indexOf("youtu") !== -1) {
    const ID = youtube_parser(url);
    if (ID) {
      formattedURL = `https://www.youtube.com/embed/${ID}?autoplay=1&rel=0`;
    }
  } else if (url.indexOf("vimeo") !== -1) {
    const ID = vimeo_parser(url);
    if (ID) {
      formattedURL = `https://player.vimeo.com/video/${ID}?h=5d4560c5a6&color=00a7a1&title=0&byline=0&portrait=0&autoplay=1`;
    }
  }

  if (formattedURL !== "") {
    link.addEventListener("click", function(event) {
      event.preventDefault();
      const replacementDiv = document.createElement('div');
      const videoEmbed = `<iframe class="w-full aspect-video" src="${ formattedURL }" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
      replacementDiv.innerHTML = videoEmbed;
      link.replaceWith(replacementDiv);
    })
  }
});