const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const tables = $$(".content table");

tables.forEach((table) => {
  const wrapper = document.createElement("div");
  wrapper.classList.add("table-scroll");
  table.parentNode.insertBefore(wrapper, table);
  wrapper.appendChild(table);
});