const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

class FloatParallax {
  constructor(target) {
    this.viewportHeight = 0;
    this.distance = 50;
    this.fromTop = false;
    this.time = Date.now();
    this.wait = 10;
    this.target = target;
    this.desktop = window.matchMedia('(min-width: 768px)');

    this.boundScroll = () => this.onScroll();
    this.boundThrottle = (fn, wait) => this.throttle(fn, wait);
  }

  easeInOut(currentProgress) {
    currentProgress /= this.viewportHeight / 2;
    if (currentProgress < 1) {
      return Math.round((this.distance / 2) * (Math.pow(currentProgress, 3)));
    }
    currentProgress -= 2;
    return Math.round(this.distance / 2 * (Math.pow(currentProgress, 3) + 2));
  }

  triggerIntersection(fromTop, viewportHeight) {
    this.viewportHeight = viewportHeight;
    this.linkToScroll();
  }

  leaveIntersection() {
    this.unlinkToScroll();
  }

  linkToScroll() {
    this.startY = window.scrollY;
    window.addEventListener("scroll", this.boundScroll, false);
  }

  unlinkToScroll() {
    window.removeEventListener("scroll", this.boundScroll, false);
  }

  onScroll() {
    if ((this.time + this.wait - Date.now()) < 0) {
      let imageTop = this.target.getBoundingClientRect().top;
      if (imageTop > 0) {
        let move = this.distance - this.easeInOut(imageTop);
        if (!this.desktop.matches) {
          move = 0;
        }
        this.target.style.transform = `translateY(${move}px)`;
        this.time = Date.now();
      }
    }
  }
}

const animationElements = $$(".float-parallax");
const animations = [];

let options = {
  root: document.querySelector("null"),
  rootMargin: "0% 0% -25% 0%",
  threshold: 0
};

let callback = function animationCallback(entries, observer) {
  entries.forEach(entry => {
    let animationIndex = Array.prototype.indexOf.call(animationElements, entry.target);
    if (entry.isIntersecting) {
      let fromTop = false;
      if (entry.intersectionRect.y === 0) {
        fromTop = true;
      }
      animations[animationIndex].triggerIntersection(fromTop, entry.rootBounds.height);
    } else {
      animations[animationIndex].leaveIntersection();
    }
  });
};

let observer = new IntersectionObserver(callback, options);

animationElements.forEach(function animate(animation) {
  let target = $(".float-target", animation);
  let floatAnimation = new FloatParallax(target);

  animations.push(floatAnimation);
  observer.observe(animation);
});