const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const shareTool = $(".article-share-tool");
const shareButton = $(".share");

if (shareTool) {
  let url = document.location.href;
  let title = document.title;

  $(".article-share-tool .icon-facebook")?.setAttribute("href",`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&t=${encodeURIComponent(title)}`);
  $(".article-share-tool .icon-twitter")?.setAttribute("href",`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`);
  $(".article-share-tool .icon-linkedin")?.setAttribute("href",`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`);
  const mailHREF = $(".article-share-tool .mailto")?.getAttribute("href");
  $(".article-share-tool .mailto")?.setAttribute("href", `${mailHREF}&body=${encodeURIComponent(url)}`);
}

if (shareButton) {
  document.addEventListener("click", function(event) {
    const popup = event.target.closest(`.share-popup`);
    if (!popup) {
      shareButton.parentNode.classList.remove("open-share");
      return;
    }
  });

  document.addEventListener("click", function(event) {
    const close = event.target.closest(`.share-popup .close`);
    if (!close) {
      return;
    }

    shareButton.parentNode.classList.toggle("open-share");
  });

  document.addEventListener("click", function(event) {
    const button = event.target.closest(`.share`);
    if (!button) {
      return;
    }

    button.parentNode.classList.toggle("open-share");
  });

  document.addEventListener("click", function(event) {
    const copy = event.target.closest(`.share-popup .copy`);
    if (!copy) {
      return;
    }

    navigator.clipboard.writeText(window.location.href).then(() => {
      $(".share-popup #popup-message").textContent = "Copied page URL to clipboard";
    })
  });
}