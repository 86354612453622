import AOS from "../common/aos.js";
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const desktopMediaQuery = window.matchMedia('(min-width: 1024px)');
const tabletMediaQuery = window.matchMedia('(min-width: 768px)');

const homeHeroBox = $(".home .hero .content-box");
if (homeHeroBox) {
  const h1 = $("h1", homeHeroBox);
  const paragraphs = $$("p", homeHeroBox);
  h1.classList.add("reveal");
  setTimeout(function() {
    paragraphs.forEach(function(paragraph) {
      paragraph.classList.add("reveal");
    })
  }, 500)
}

document.querySelectorAll(".four-column figure, .three-column figure").forEach(figure => {
  figure.setAttribute("data-aos","fade-up");
});

if (tabletMediaQuery.matches) {
  document.querySelectorAll(".float-target img").forEach(figure => {
    figure.setAttribute("data-aos","fade-up");
  });
}

if (desktopMediaQuery.matches) {
  document.querySelectorAll(".graphic-placement img, .page-list-insights article").forEach(figure => {
    figure.setAttribute("data-aos","fade-up");
  });
}

AOS.init();