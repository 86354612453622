const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const desktopMediaQuery = window.matchMedia('(max-width: 1023px)');
const mobileMenu = $("header[role='banner'].platforma-nav-with-utility");

function toggleMobileMenu() {
  const button = $(`.platforma-nav-with-utility [aria-controls="primary-nav"]`);
  const state = button.getAttribute("aria-expanded");
  const isOpen = state === "true";
  const mobileMenu = $("header[role='banner'].platforma-nav-with-utility");

  button.setAttribute("aria-expanded", isOpen ? "false" : "true");
  button.innerText = isOpen ? "Menu" : "Close";
  mobileMenu.classList.toggle("menu-open");
  $("body").classList.toggle("overflow-hidden");
}

function openSubnav(parent) {
  const button = $("button", parent);

  parent?.classList.add("open");
  button?.setAttribute(`aria-expanded`, `true`);
}

function closeSubnav(parent) {
  const button = $("button", parent);

  parent?.classList.remove("open");
  button?.setAttribute(`aria-expanded`, `false`);
}

function closeSubnavs() {
  const openMenus = $$(".platforma-nav-with-utility .isParent.open, .platforma-nav-with-utility .isGrandParent.open");
  openMenus.forEach(function(menu) {
    closeSubnav(menu);
  })
}

document.addEventListener("click", function(event) {
  const button = event.target.closest(`.platforma-nav-with-utility [aria-controls="primary-nav"]`);
  if (!button) {
    return;
  }

  toggleMobileMenu();
});

document.addEventListener('touchend', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility:not(.menu-open) .isParent > a, .platforma-nav-with-utility:not(.menu-open) .isGrandParent > a");
  if ($(".platforma-nav-with-utility.menu-open")) {
    return;
  }
  if (!closest){
    closeSubnavs();
    return;
  }
  let parent = closest.parentNode;
  if (parent.classList.contains("open")) return;

  closeSubnavs();
  openSubnav(parent);
  event.preventDefault();
}, false);

document.addEventListener('mouseover', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility:not(.menu-open) nav > ul > .isParent, .platforma-nav-with-utility:not(.menu-open) nav > ul > .isGrandParent");
  if (!closest) return;
  openSubnav(closest);
}, false);

document.addEventListener('mouseout', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility:not(.menu-open) nav > ul > .isParent, .platforma-nav-with-utility:not(.menu-open) nav > ul > .isGrandParent");
  if (!closest) return;
  closeSubnav(closest);
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".platforma-nav-with-utility:not(.menu-open) .primary-nav a + button");
  if (!closest) return;

  let $toggle = closest;
  let $navItem = $toggle.closest(`li`);
  let $otherNavItems = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  $otherNavItems.forEach(item => {
    closeSubnav(item);
  })

  if ($navItem.classList.contains("open")) {
    closeSubnav($navItem);
  } else {
    openSubnav($navItem);
  }
}, false);

document.addEventListener('click', function (event) {
  const targets = `
    .platforma-nav-with-utility.menu-open nav .isParent > a,
    .platforma-nav-with-utility.menu-open nav .isParent > button,
    .platforma-nav-with-utility.menu-open nav .isGrandParent > a,
    .platforma-nav-with-utility.menu-open nav .isGrandParent > button
  `

  const closest = event.target.closest(targets);
  if (!closest) return;

  const $navItem = closest.parentNode;
  let siblings = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  event.preventDefault();

  if ($navItem.classList.contains(`open`)) {
    closeSubnav($navItem);
  } else {
    openSubnav($navItem);
    siblings.forEach(sibling => {
      closeSubnav(sibling);
    })
  }
}, false);

function throttle(fn, wait) {
  let time = Date.now();
  return function() {
    if ((time + wait - Date.now()) < 0) {
      fn();
      time = Date.now();
    }
  }
}

function scrollTo(id) {
  let $el = $(id);
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  let $header = $("header[role='banner']");

  if (!$el) {
    return;
  }

  let headerOffset = 0 + $header.offsetHeight;
  if ($("body").classList.contains("samadmin")) {
    headerOffset = headerOffset + 97;
  }

  window.scroll({
    top: $el.getBoundingClientRect()["top"] + scrollTop
          - headerOffset
          - 45 /* Extra padding for visual look */,
    behavior: 'smooth'
  });
};

function setHeaderTop(header) {
  let height = header.offsetHeight;
  if ($("body").classList.contains("samadmin")) {
    let adminBarHeight = $("#SAMAdminTools").offsetHeight;
    height = height - adminBarHeight;
  }
  height = height * -1;
  header.style.top = `${height}px`;
}

let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
let header = $("header.platforma-nav-with-utility");
let inPageHeader = true;
let resizeTimer;

if (header) {
  window.addEventListener('load', function () {
    setHeaderTop(header);
  })

  window.addEventListener('resize', function(e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      setHeaderTop(header);

      if (!desktopMediaQuery.matches) {
        if (mobileMenu.classList.contains("menu-open")) {
          toggleMobileMenu();
        }
      }
    }, 250);
  });
}

let scrollCallback = function(){

  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  let diff = Math.abs(lastScrollTop - scrollTop);
  if (!header.classList.contains("menu-open")) {
    if (scrollTop> lastScrollTop){
      if (header.classList.contains("unfold") && diff > 10) {
        header.classList.remove("unfold");
        header.style.transform = `translateY(0px)`;
      }
    } else {
      if (!header.classList.contains("unfold") && diff > 10 && !inPageHeader) {
        header.classList.add("unfold");
        header.style.transform = `translateY(${header.offsetHeight}px)`;
      } else if (header.classList.contains("unfold") && inPageHeader) {
        header.classList.remove("unfold");
        header.style.transform = `translateY(0px)`;
      }
    }
  }

  if (diff > 10) {
    lastScrollTop = scrollTop<= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
  }
}

let intersectionCallback = (entries, observer) => {
  entries.forEach(entry => {
    inPageHeader = entry.isIntersecting;
  });
};

let options = {
  root: null,
  rootMargin: `0px`,
  threshold: 0
}

let observer = new IntersectionObserver(intersectionCallback, options);
observer.observe($("main > *:first-child"));
window.addEventListener("scroll", throttle(scrollCallback, 25) , false);